/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import get from "lodash/get"
import { graphql } from "gatsby"
import { paramsCheck } from "../functions/functions"
import Page from "../components/page"
import Hero from "../components/hero"
import { findBySlug, renderLineBreak } from "../helpers"
import Container from "../components/container"
import Column from "../components/column"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"
import cloneDeep from "lodash/cloneDeep"
import ProjectUnique from "../components/project-unique"
import CTAsModule from "../components/ctas-module"
import Carousel from "../components/carousel"
import { Button } from "@theme-ui/components"
import theme from "../gatsby-plugin-theme-ui/index"
import GatewayForm from "../components/gateway-form"
import VideoInline from "../components/videoinline"
import CustomFormModal from "../components/custom-form-modal"

const TwoColumnsModule = ({
  bladePatternData,
  columnSizes,
  titleStyle,
  showBios,
  splitKeyWord,
  background,
  bladePatternData2,
}) => {
  const [showMoreContent, setShowMoreContent] = React.useState(0)
  if (!bladePatternData.contentCards || !bladePatternData.contentCards.length)
    return null

  // const leftColumn = bladePatternData.contentCards[0].description.content

  // const rightColumn = bladePatternData.contentCards.slice(
  //   1,
  //   bladePatternData.contentCards[0].description.content.length
  // )

  const rightColumn = bladePatternData.contentCards[0]
  const readMoreIdx = rightColumn.description.json.content.findIndex(
    (node, idx) => {
      const nodeContent = get(node, "content[0].value", "")
      // return nondeContet.trim() == "<How we got here>"
      return nodeContent.trim() == `<${splitKeyWord}>`
    }
  )

  let initialContent = cloneDeep(rightColumn.description)
  let readMoreContent = cloneDeep(rightColumn.description)
  if (readMoreIdx) {
    initialContent.json.content = initialContent.json.content.slice(
      0,
      readMoreIdx
    )
    readMoreContent.json.content = readMoreContent.json.content.slice(
      readMoreIdx + 1,
      readMoreContent.json.length
    )
  }

  return (
    <div
      sx={
        background === "primary"
          ? {
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
            }
          : {
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
              backgroundColor: theme => theme.colors.backgrounds.primary,
            }
      }
    >
      <Container
        customStyle={{
          px: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
        }}
      >
        <Row>
          <Column size={get(columnSizes, "left", [12, 12, 4])}>
            {bladePatternData.title && (
              <Text
                type="h2"
                customStyle={{
                  mb: theme => theme.spacing.vertical.sm,
                  mx: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.md,
                  ],
                  // mt: theme => theme.spacing.vertical.md,
                  ...titleStyle,
                }}
              >
                {renderLineBreak(bladePatternData.title)}
              </Text>
            )}
            {bladePatternData2 && (
              <Carousel
                type={"tertiary"}
                customStyle={{
                  width: "75%",
                  ml: theme => [
                    theme.spacing.vertical.sm,
                    theme.spacing.vertical.md,
                  ],
                }}
              >
                {bladePatternData2.contentCards.map(card => (
                  <img
                    alt=""
                    sx={{ borderRadius: "12px" }}
                    src={card.desktopImage.file.url}
                  />
                ))}
              </Carousel>
            )}
          </Column>
          <Column size={get(columnSizes, "right", [12, 12, 8])}>
            <Row>
              {initialContent ? (
                <RichText data={initialContent} showBios={showBios} />
              ) : null}
              {readMoreIdx && !showMoreContent ? (
                <div
                  onClick={() => setShowMoreContent(1)}
                  onKeyDown={() => setShowMoreContent(1)}
                  role="button"
                  tabIndex="0"
                  sx={{ cursor: "pointer" }}
                >
                  <Text
                    customStyle={{
                      color: theme => theme.colors.primary,
                      mt: theme => theme.space[3],
                    }}
                  >
                    {splitKeyWord}
                    <span
                      sx={{
                        fontSize: 14,
                        ml: 2,
                        display: "inline-block",
                        transform: "scaleX(1.8) translateY(-5px)",
                      }}
                    >
                      {"\u2304"}
                    </span>
                  </Text>
                </div>
              ) : null}
              {readMoreIdx && showMoreContent ? (
                <div>
                  <div
                    onClick={() => setShowMoreContent(0)}
                    onKeyDown={() => setShowMoreContent(0)}
                    role="button"
                    tabIndex="0"
                    sx={{ cursor: "pointer" }}
                  >
                    <Text
                      customStyle={{
                        color: theme => theme.colors.primary,
                        mt: theme => theme.space[3],
                        mb: theme => theme.space[3],
                      }}
                    >
                      {splitKeyWord}
                      <span
                        sx={{
                          fontSize: 14,
                          ml: 2,
                          display: "inline-block",
                          transform:
                            "scaleX(1.8) translateY(7px) rotate(180deg)",
                        }}
                      >
                        {"\u2304"}
                      </span>
                    </Text>
                  </div>
                  <RichText data={readMoreContent} />
                </div>
              ) : null}
            </Row>
          </Column>
        </Row>
      </Container>
    </div>
  )
}

const PlantBuildingEnvelope = ({ data }) => {
  const [showBiosModal, setShowBiosModal] = React.useState(false)
  const [biosData, setBiosData] = React.useState(null)

  var addField = {
    tags: "PBSDownload",
  }

  paramsCheck()

  const setBiosLetterModal = data => {
    data && data.bios === null
      ? setShowBiosModal(false)
      : setShowBiosModal(true)
    data.isLetter = true
    setBiosData(data)
  }

  const renderHeroBladePattern = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!bladePatternData) return

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: bladePatternData.mobileImage,
            desktopImage: bladePatternData.desktopImage,
          },
        }}
        type="secondary"
        verticalAlign="top"
        title={bladePatternData.title}
        // body={bladePatternData.description.description}
      />
    )
  }

  const renderIntroduction = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-introducing-the-plant-building-envelope",
    })

    if (!bladePatternData) return

    return (
      <TwoColumnsModule
        bladePatternData={bladePatternData}
        titleStyle={{ fontSize: [30, 30, 40] }}
        columnSizes={{ left: [12, 12, 4], right: [12, 12, 8] }}
        showBios={() => setBiosLetterModal(bladePatternData.contentCards[0])}
        splitKeyWord={"How we got here"}
        background={"primary"}
      />
    )
  }

  const renderServicesModule = () => {
    const eachProjectIsUniqueBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "page-blade-pattern-each-project-is-unique",
    })

    return (
      <ProjectUnique
        data={eachProjectIsUniqueBladePatternData}
        customStyle={
          {
            // mx:theme=>[theme.spacing.vertical.sm,theme.spacing.vertical.md]
          }
        }
      ></ProjectUnique>
    )
  }

  const [showModal, setShowModal] = React.useState(false)
  const [gatewayLink, setGatewayLink] = React.useState({
    to: "/",
    type: "internal",
  })

  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-cta-module",
    })
    if (!ctaModuleBladePatternData) return
    addField.detail = `PBS Overview Download`;
    addField.goalName = `PBS Overview Download Form Submit`;
    addField.conversionDetail = `xXoxCM_Wz9wBEJeLhv0C`;
    return (
      <>
        {showModal && (
          // <GatewayForm
          //   onClose={() => setShowModal(false)}
          //   detail={`PBS Overview Download`}
          //   addFieldsDefault={addField}
          //   goalName={`PBS Overview Download Form Submit`}
          //   conversionDetail={`xXoxCM_Wz9wBEJeLhv0C`}
          //   onSubmit={() => {
          //     const win = window.open(gatewayLink.to, "_blank")
          //     win.focus()
          //   }}
          // />
          <CustomFormModal
            title={"Download"}
            text={"Please answer a few quick questions to help us understand your needs."}
            onClose={() => setShowModal(false)}
            addFields={addField}
            onSubmit={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
            downloadPlan={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
          />
        )}
        <CTAsModule
          // customStyle={{px:theme=>[theme.spacing.vertical.sm,theme.spacing.vertical.lg]}}
          textStyle={{ color: theme => theme.colors.white }}
          bladePatternData={ctaModuleBladePatternData}
          ctaOnClick={(e, index, href) => {
            e.preventDefault()
            if (index === 0) {
              window.location.href = "/contact"
            }
            if (index === 1) {
              setShowModal(true)
              if (href) setGatewayLink(href)
            }
          }}
        />
      </>
    )
  }

  const renderCompanyValuesModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-fastflexiblefeasible",
    })
    const VideoBlade = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-video-blade",
    })

    if (!bladePatternData) return

    return (
      <div
        sx={{
          py: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
          ],
          backgroundColor: theme => theme.colors.backgrounds.primary,
        }}
        // className="pb-0"
      >
        <Container
          customStyle={{
            px: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.lg],
          }}
        >
          <Row
          // customStyle={{ mb: theme => theme.spacing.vertical.sm }}
          >
            <Column customStyle={{ mt: theme => theme.spacing.vertical.sm }}>
              <Text type="h2">{renderLineBreak(bladePatternData.title)}</Text>
            </Column>
          </Row>
          <Row
            customStyle={{
              py: theme => [
                theme.spacing.vertical.sm,
                theme.spacing.vertical.md,
              ],
            }}
          >
            <Column
            // customStyle={{py:theme=>theme.spacing.vertical.sm}}
            >
              {VideoBlade && <VideoInline video={VideoBlade} />}
            </Column>
          </Row>
          {bladePatternData.contentCards ? (
            <Row>
              {bladePatternData.contentCards.map((basicCard, idx) => (
                <Column
                  size={[12, 12, 4]}
                  customStyle={{
                    pr: [0, 0, 30, 60],
                    ":nth-child(3), :nth-child(6)": {
                      pr: 0,
                      maxWidth: [
                        null,
                        null,
                        `calc(${100 / 3}% - 30px)`,
                        `calc(${100 / 3}% - 60px)`,
                      ],
                      maxheight: "max-content",
                    },
                  }}
                  key={idx}
                >
                  <div className="pb-0">
                    <Text
                      type="h3"
                      customStyle={{
                        mb: theme => theme.spacing.vertical.sm,
                      }}
                    >
                      {renderLineBreak(basicCard.title)}
                    </Text>
                    {basicCard.description ? (
                      // <div className="pb-0">
                      <RichText data={basicCard.description} />
                    ) : // </div>

                    null}
                  </div>
                </Column>
              ))}
            </Row>
          ) : null}
        </Container>
      </div>
    )
  }

  const renderBuildingBlock = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-better-building-blocks",
    })
    const bladePatternData2 = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-carousel",
    })

    // console.log("addaa",bladePatternData2)

    if (!bladePatternData) return
    if (!bladePatternData2) return

    return (
      <TwoColumnsModule
        bladePatternData={bladePatternData}
        titleStyle={{ fontSize: [30, 30, 40] }}
        columnSizes={{ left: [12, 12, 4], right: [12, 12, 8] }}
        showBios={() => setBiosLetterModal(bladePatternData.contentCards[0])}
        splitKeyWord={"How this works"}
        background={"secondary"}
        bladePatternData2={bladePatternData2}
      />
    )
  }

  const renderEfficiencyModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "pbe-efficiency-module",
    })
    const titleStyle = `{{ fontSize: [30, 30, 40] }}`
    const columnSizes = `{{ left: [12, 12, 4], right: [12, 12, 8] }}`
    if (!bladePatternData) return
    // console.log("efficiency",bladePatternData)
    addField.detail = `PBS Overview Download`;
    addField.goalName = `PBS Overview Download Form Submit`;
    addField.conversionDetail = `xXoxCM_Wz9wBEJeLhv0C`;
    return (
      <div
        sx={{
          py: theme => [theme.spacing.vertical.sm, theme.spacing.vertical.md],
          pt: theme => [
            theme.spacing.vertical.sm,
            theme.spacing.vertical.md,
            theme.spacing.vertical.lg,
          ],
        }}
      >
        {showModal && (
          // <GatewayForm
          //   onClose={() => setShowModal(false)}
          //   addFieldsDefault={addField}
          //   detail={`PBS Overview Download`}
          //   goalName={`PBS Overview Download Form Submit`}
          //   conversionDetail={`xXoxCM_Wz9wBEJeLhv0C`}
          //   onSubmit={() => {
          //     // setShowModal(false)
          //     const win = window.open(gatewayLink.to, "_blank")
          //     win.focus()
          //   }}
          // />
          <CustomFormModal
            title={"Download"}
            text={"Please answer a few quick questions to help us understand your needs."}
            onClose={() => setShowModal(false)}
            addFields={addField}
            onSubmit={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
            downloadPlan={() => {
              let win = window.open(gatewayLink.to, "_blank")
              win.focus()
            }}
          />
        )}
        <Container
          customStyle={{
            px: theme => [theme.spacing.vertical.md],
          }}
        >
          <Row>
            <Column
              size={get(columnSizes, "left", [12, 12, 4])}
              customStyle={{
                px: theme => [
                  theme.spacing.vertical.sm,
                  theme.spacing.vertical.md,
                ],
              }}
            >
              {bladePatternData.title && (
                <Text
                  type="h2"
                  customStyle={{
                    mb: theme => theme.spacing.vertical.sm,
                    // minWidth:"max-content",
                    ...titleStyle,
                  }}
                >
                  {renderLineBreak(bladePatternData.title)}
                </Text>
              )}
              {bladePatternData.description ? (
                <Text>{bladePatternData.description.description}</Text>
              ) : null}
              <Button
                sx={{
                  ...theme.forms.buttonChevron,
                  fontSize: [14, 16],
                  pr: 7,
                  py: "5px",
                  pl: 19,
                  mt: 26,
                  mb: 26,
                  display: "flex",
                  justifyContent: "space-between",
                  // width: 290,
                  color: "primary",
                  border: theme => `2px solid ${theme.colors.primary}`,
                  background: "transparent",
                  whiteSpace: "nowrap",
                  ":hover": {
                    border: theme => `2px solid ${theme.colors.secondary}`,
                    color: "secondary",
                  },
                }}
                onClick={e => {
                  e.preventDefault()
                  setShowModal(true)
                  // to: "https://downloads.ctfassets.net/c0wa9gntjrmf/1sK48cC4aoajbNmu5KynVw/6214ce97030760d4a90ea822a33e10ea/PBS_DESIGN_GUIDLINES.pdf"
                  if (bladePatternData.contentCards[0].ctaDestinationExternal)
                    setGatewayLink({
                      to:
                        bladePatternData.contentCards[0].ctaDestinationExternal
                          .ctaDestinationExternal,
                      type: "external",
                    })
                }}
              >
                {
                  bladePatternData.contentCards[0].description.json.content[0]
                    .content[0].value
                }
              </Button>
            </Column>
            <Column size={get(columnSizes, "right", [12, 12, 8])}>
              <img
                src={bladePatternData.desktopImage.file.url}
                alt=""
                sx={{
                  width: "100%",
                  mb: [3, 3, 0, 0],
                  borderRadius: "5px",
                }}
              />
            </Column>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Page data={data.contentfulPage}>
        {renderHeroBladePattern()}
        {renderIntroduction()}
        {renderBuildingBlock()}
        {renderEfficiencyModule()}
        {renderCompanyValuesModule()}
        {renderCtaModule()}
        {renderServicesModule()}
      </Page>
    </React.Fragment>
  )
}
export default PlantBuildingEnvelope

export const query = graphql`
  query plantBuildingEnvelopeQuery {
    contentfulPage(slug: { eq: "plant-building-envelope" }) {
      ...PageData
      ...BladePatternData
    }
  }
`
