import { jsx } from "theme-ui"
import React from "react"
import { useRef } from "react"
import { useEffect } from "react"
import queryString from "query-string"

const VideoInline = ({ video, type, customStyle, title }) => {
  const videoRef = useRef(null)
  const play = () => {
    videoRef.current.play()
  }
  // let url = video.contentCards[0].video.file.url;

  useEffect(() => {
    if (video) {
      play()
    }
  }, [video])

  return (
    <React.Fragment>
      <video
        preload
        playsinline
        poster={video.mobileImage.file.url}
        ref={videoRef}
        loop
        autoplay
        muted
        width="100%"
        height="100%"
        controls
      >
        <source src={video.contentCards[0].video.file.url} type="video/mp4" />
        <source
          src={video.contentCards[1].video.file.url}
          type="video/webm; codecs=vp8, vorbis"
        />
        <source src={video.contentCards[2].video.file.url} type="video/ogg" />
        {/* <object data={video.contentCards[0].video.file.url} />
                <embed src="video.swf" /> */}
      </video>
    </React.Fragment>
  )
}

export default VideoInline
